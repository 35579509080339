<template>
    <div>
        <stamped-documents title="Con fecha de factura distinta de la fecha de venta" />
    </div>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardText,
    BCardBody,
    BCardTitle,
    BTable,
    BTableLite,
    BOverlay,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormGroup,
    BFormSelect,
    BBadge,
    BFormRadioGroup,
    BPagination,
    BTooltip,
    VBTooltip,

} from 'bootstrap-vue'
import { ref, onMounted, computed, watch, reactive, defineEmits, nextTick } from "@vue/composition-api";
import store from '@/store'
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import * as helper from '@/libs/helpers';
import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex';
import moment from 'moment';
import * as XLSX from 'xlsx';
import StampedDocuments from '@/@core/components/invoices/StampedDocuments.vue';

import DoctosPVProvider from '@/providers/DoctosPV';
const DoctosPVResource = new DoctosPVProvider();

export default {
    name: "DeliveriesByDriver",
    components: {
        BCard,
        BCardBody,
        BCardHeader,
        BCardTitle,
        BCardText,
        BTable,
        BTableLite,
        BOverlay,
        BSpinner,
        BRow,
        BCol,
        BButton,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BFormGroup,
        BFormSelect,
        BBadge,
        BFormRadioGroup,
        BPagination,
        BTooltip,
        VBTooltip,

        //
        flatPickr,
        vSelect,
        StampedDocuments,
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    setup() {
       

        return {
       
        }
    },
}

</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>